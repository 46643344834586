/* eslint-disable @typescript-eslint/camelcase */
<template>
  <div class="container">
    <TableComponent :blockData="blockData" @update-page="fetchPage" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import TableComponent from "@/views/pages/blocks/TableComponent.vue";
import store from "@/store";

export default defineComponent({
  name: "TableData",
  components: {
    TableComponent
  },
  data() {
    return {
      blockData: {}
    };
  },
  setup() {
    localStorage.setItem("clear", "0");
    const currentPage = localStorage.getItem("currentPage");
    store.dispatch("fetchPageData", currentPage);
    onMounted(() => {
      setCurrentPageTitle("Blocks List and Details");
    });
  },
  created() {
    // Delay is the key here !
    setTimeout(() => {
      if (localStorage.getItem("clear") === "0") {
        this.blockData = store.getters.allBlocksData;
      }
    }, 500);
  },

  methods: {
    fetchPage($event) {
      store.dispatch("fetchPageData", $event);

      setTimeout(() => {
        this.blockData = store.getters.allBlocksData;
      }, 500);
    }
  }
});
</script>
