import { defineComponent } from "vue";
export default defineComponent({
    name: "TableComponent",
    props: {
        blockData: Object
    },
    emits: ["updatePage"],
    data: function () {
        return {
            pageNum: null
        };
    },
    created: function () {
        var _this = this;
        setTimeout(function () {
            _this.initAnchorState();
            _this.toggleAnchorLinks(_this.currentPage);
            localStorage.setItem("clear", "1");
            localStorage.setItem("currentPage", _this.currentPage);
        }, 500);
    },
    computed: {
        currentPage: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.current_page;
        },
        dataBlocks: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.data;
        },
        firstPageUrl: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.first_page_url;
        },
        from: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.from;
        },
        lastPage: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.last_page;
        },
        lastPageUrl: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.last_page_url;
        },
        links: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.links;
        },
        nextPageUrl: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.next_page_url;
        },
        path: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.path;
        },
        perPage: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.per_page;
        },
        prevPageUrl: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.prev_page_url;
        },
        to: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.to;
        },
        total: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.total;
        },
        stats: function () {
            var _a;
            return (_a = this.blockData) === null || _a === void 0 ? void 0 : _a.stats;
        }
    },
    methods: {
        /**
         * Emits an event to parent component to update page from API
         * @param event Event object
         * @returns void
         */
        sendPage: function ($event) {
            var payload;
            if ($event.target.textContent === "« Previous") {
                if (this.currentPage > 1) {
                    payload = this.currentPage - 1;
                }
                else {
                    payload = 1;
                }
            }
            else if ($event.target.textContent === " Next » ") {
                if (this.currentPage < this.lastPage) {
                    payload = this.currentPage + 1;
                }
                else {
                    payload = this.lastPage;
                }
            }
            else {
                payload = Number.parseInt($event.target.textContent);
            }
            // Handle previous and next links
            this.toggleAnchorLinks(payload);
            localStorage.setItem("currentPage", payload.toString());
            var KEY_ENTER = 13;
            if ($event.keyCode === KEY_ENTER) {
                this.$emit("updatePage", this.pageNum);
            }
            else if ($event.type === "click" &&
                $event.target.textContent === "Jump") {
                this.$emit("updatePage", this.pageNum);
            }
            else if ($event.type === "click") {
                this.$emit("updatePage", payload);
            }
        },
        /**
         * Initializes active and non active pagination links
         * @param number pageNumber
         * @returns void
         */
        initAnchorState: function () {
            var links = this.links;
            for (var i = 0; i < links.length; i++) {
                if (i == 0 || i == links.length - 1) {
                    continue;
                }
                this.setAnchorState(i, links[i].active);
            }
        },
        /**
         * Marks active pagination link
         * @param number pageNumber
         * @returns void
         */
        setAnchorState: function (index, status) {
            var anchorTag = document.getElementById("anchor-" + index);
            if (anchorTag != null) {
                if (status) {
                    setTimeout(function () {
                        if (anchorTag.style) {
                            anchorTag.style.cssText = "\n              color: #00BFFF;\n              cursor: not-allowed;\n              opacity: 0.5;\n              text-decoration: none;\n              pointer-events: none;\n              ";
                        }
                    }, 1);
                }
                else {
                    setTimeout(function () {
                        if (anchorTag.style != null || undefined) {
                            anchorTag.style.cssText = "\n              color: \"\";\n              cursor: \"\";\n              opacity: \"\";\n              text-decoration: \"\";\n              pointer-events: \"\";\n              ";
                        }
                        else {
                            //
                        }
                    }, 1);
                }
            }
        },
        /**
         * Togggle pagination links
         * @param number pageNumber
         * @returns void
         */
        toggleAnchorLinks: function (payload) {
            var firstIndex = "0";
            var lastIndex = (this.links.length - 1).toString();
            var nextAnchorTag = document.getElementById(lastIndex);
            var prevAnchorTag = document.getElementById(firstIndex);
            // Last page => disable Next link
            if (payload == this.lastPage) {
                if (nextAnchorTag === null || nextAnchorTag === void 0 ? void 0 : nextAnchorTag.style) {
                    nextAnchorTag.style.cssText = "\n              color: currentColor;\n              cursor: not-allowed;\n              opacity: 0.5;\n              text-decoration: none;\n              pointer-events: none;\n              ";
                }
            }
            // on every other link
            else if (payload < this.lastPage) {
                if (payload != 1) {
                    if (nextAnchorTag === null || nextAnchorTag === void 0 ? void 0 : nextAnchorTag.style) {
                        nextAnchorTag.style.cssText = "\n              color: \"\";\n              cursor: \"\";\n              opacity: \"\";\n              text-decoration: \"\";\n              pointer-events: \"\";\n              ";
                    }
                    if (prevAnchorTag === null || prevAnchorTag === void 0 ? void 0 : prevAnchorTag.style) {
                        prevAnchorTag.style.cssText = "\n              color: \"\";\n              cursor: \"\";\n              opacity: \"\";\n              text-decoration: \"\";\n              pointer-events: \"\";\n              ";
                    }
                }
                else {
                    //  disable Previous link
                    if (prevAnchorTag === null || prevAnchorTag === void 0 ? void 0 : prevAnchorTag.style) {
                        prevAnchorTag.style.cssText = "\n              color: currentColor;\n              cursor: not-allowed;\n              opacity: 0.5;\n              text-decoration: none;\n              pointer-events: none;\n              ";
                    }
                }
            }
        },
        /**
         * Formats number with leading hashtag and zeros
         * @returns string
         */
        pad: function (num) {
            //fixed size
            var SIZE = 9;
            num = num.toString();
            while (num.length < SIZE) {
                num = "0" + num;
            }
            return "#" + num;
        },
        /**
         * Calculates the time since block's creation
         * @returns string
         */
        calcElapsedTime: function (time) {
            //if it's a protection block
            if (time == null) {
                return "";
            }
            else {
                // data string to milisec
                var createdTime = Number(new Date(time)); // UTC time
                var nowUtc = Number(new Date(this.getCurrentTimeUTC())); // UTC time
                var diff = Number(nowUtc - createdTime);
                return this.msToTime(diff);
            }
        },
        /**
         * Gets the UTC time
         * @returns string
         */
        getCurrentTimeUTC: function () {
            //RETURN:
            //      = number of milliseconds between current UTC time and midnight of January 1, 1970
            var tmLoc = new Date();
            //The offset is in minutes -- convert it to ms
            return tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;
        },
        /**
         * Formats miliseconds to hh:mm:ss
         * @returns string
         */
        msToTime: function (s) {
            // Pad to 2 or 3 digits, default is 2
            function pad(n, z) {
                z = z || 2;
                return ("00" + n).slice(-z);
            }
            var ms = s % 1000;
            s = (s - ms) / 1000;
            var secs = s % 60;
            s = (s - secs) / 60;
            var mins = s % 60;
            var hrs = (s - mins) / 60;
            return pad(hrs) + ":" + pad(mins) + ":" + pad(secs) + "." + pad(ms, 3);
        }
    }
});
