<template>
  <!--begin::Table Component-->

  <div class="mb-5 mb-xl-8 card">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5 ">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label text-dark fw-bolder fs-3 mb-1">Blocks</span>
        <span class="text-muted mt-1 fw-bold fs-7">
          All the blocks in the chain
        </span>
      </h3>
      <h3 class="card-title align-items-start flex-column me-5">
        <span class="text-muted mt-1 fw-bold fs-3">
          Showing {{ from }} - {{ to }} of {{ total }} total blocks
        </span>
      </h3>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3">
      <!--begin::Table container-->
      <div class="table-responsive">
        <!--begin::Table-->
        <table
          class="table table-dark table-striped table-hover table-sm table-row-bordered table-row-gray-100 align-middle gs-0 gy-3"
        >
          <!--begin::Table head-->
          <thead>
            <tr class="bg-dark fw-bolder text-white align-middle">
              <th class="min-w-120px fs-2 ps-5">#</th>
              <th class="min-w-120px fs-2">BlockID</th>
              <th class="min-w-120px fs-2">Age</th>
              <th class="min-w-120px fs-2">Type</th>
              <th class="min-w-120px fs-2">Hash</th>
              <th class="min-w-120px fs-2">Status</th>
              <th class="min-w-120px fs-2 pe-5">Info</th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
            <template v-for="(item, index) in dataBlocks" :key="index">
              <tr>
                <!--begin::Block number-->
                <td class="ps-5">
                  <span class=" text-muted fs-6">
                    {{ (currentPage - 1) * perPage + (index + 1) }}
                  </span>
                </td>
                <!--end::Block number-->

                <!--begin::BlockID-->
                <!--begin::Normal BlockID -->
                <td v-if="item.type" class="ps-5">
                  <router-link
                    :to="{ name: 'blockInfo', params: { hash: item.checksum } }"
                    class="fw-bolder text-hover-primary fs-6"
                  >
                    {{ pad(item.id) }}
                  </router-link>
                </td>
                <!--end::Normal BlockID -->
                <!--begin::Protection Block's BlockID -->
                <td class="ps-5" v-else>
                  <span class=" text-muted fs-6">
                    {{ pad(item.id) }}
                  </span>
                </td>
                <!--end::Protection Block's BlockID -->
                <!--end::BlockID-->

                <!--begin::Age-->
                <td>
                  <span class="text-muted text-hover-primary d-block mb-1 fs-6">
                    {{ calcElapsedTime(item.created_at) }}
                  </span>
                </td>
                <!--end::Age-->

                <!--begin::Type-->
                <td>
                  <span class="text-muted mb-1 fs-7">
                    {{
                      item.type ? item.type.toUpperCase() : "PROTECTION BLOCK"
                    }}
                  </span>
                </td>
                <!--end::Type-->

                <!--begin::Hash-->
                <td>
                  <span class="text-muted text-hover-primary d-block mb-1 fs-6">
                    {{ item.checksum }}
                  </span>
                </td>
                <!--end::Hash-->

                <!--begin::Status-->
                <td>
                  <!--begin::In queue blocks-->
                  <span
                    class="badge badge-light-warning"
                    v-if="item.file_hash == null"
                    >In queue</span
                  >
                  <!--end::In queue blocks-->
                  <!--begin::Registered blocks-->
                  <span class="badge badge-light-success" v-else
                    >Registered</span
                  >
                  <!--end::Registered blocks-->
                </td>
                <!--end::Status-->

                <!--begin::Hash-->
                <td>
                  <router-link
                    id="info"
                    v-if="item.type"
                    :to="{ name: 'blockInfo', params: { hash: item.checksum } }"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-info-circle"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                      />
                      <path
                        d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                      />
                    </svg>
                  </router-link>
                </td>
                <!--end::Hash-->
              </tr>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->

        <!--begin::Pagination-->
        <div class="container-fluid justify-content-end">
          <nav aria-label="Page navigation" class="row mb-2 mt-2 mt-2">
            <div class="col ">
              <ul class="pagination justify-content-end">
                <li
                  v-for="(link, index) in links"
                  :key="index"
                  class="page-item"
                >
                  <a
                    :id="'anchor-' + index"
                    class="page-link"
                    @click="sendPage"
                    :class="setAnchorState(index, link.active)"
                  >
                    <span v-if="index == 0">&laquo; Previous</span>
                    <span v-else-if="index == 14">
                      Next &raquo;
                    </span>
                    <span v-else> {{ link.label }}</span>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <!-- begin:: Jump input-->
          <div class="container-fluid justify-content-end">
            <nav aria-label="Page navigation" class="row mb-2 mt-2 mt-2">
              <div class="col ">
                <ul class="pagination justify-content-end">
                  <span class="text-muted mt-2 mx-5">Jump to page </span>
                  <li class="pagination">
                    <input
                      id="jumpInput"
                      size="1"
                      type="text"
                      v-model="pageNum"
                      @keyup="sendPage"
                    />
                  </li>
                </ul>
              </div>
            </nav>
          </div>
          <!-- end:: Jump input-->
        </div>
        <!--end::Pagination-->
      </div>
      <!--end::Table container-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Table Component-->
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "TableComponent",
  props: {
    blockData: Object
  },
  emits: ["updatePage"],
  data() {
    return {
      pageNum: null
    };
  },
  created() {
    setTimeout(() => {
      this.initAnchorState();
      this.toggleAnchorLinks(this.currentPage);
      localStorage.setItem("clear", "1");
      localStorage.setItem("currentPage", this.currentPage);
    }, 500);
  },
  computed: {
    currentPage() {
      return this.blockData?.current_page;
    },
    dataBlocks() {
      return this.blockData?.data;
    },
    firstPageUrl() {
      return this.blockData?.first_page_url;
    },
    from() {
      return this.blockData?.from;
    },
    lastPage() {
      return this.blockData?.last_page;
    },
    lastPageUrl() {
      return this.blockData?.last_page_url;
    },
    links() {
      return this.blockData?.links;
    },
    nextPageUrl() {
      return this.blockData?.next_page_url;
    },
    path() {
      return this.blockData?.path;
    },
    perPage() {
      return this.blockData?.per_page;
    },
    prevPageUrl() {
      return this.blockData?.prev_page_url;
    },
    to() {
      return this.blockData?.to;
    },
    total() {
      return this.blockData?.total;
    },
    stats() {
      return this.blockData?.stats;
    }
  },
  methods: {
    /**
     * Emits an event to parent component to update page from API
     * @param event Event object
     * @returns void
     */
    sendPage($event) {
      let payload: number;
      if ($event.target.textContent === "« Previous") {
        if (this.currentPage > 1) {
          payload = this.currentPage - 1;
        } else {
          payload = 1;
        }
      } else if ($event.target.textContent === " Next » ") {
        if (this.currentPage < this.lastPage) {
          payload = this.currentPage + 1;
        } else {
          payload = this.lastPage;
        }
      } else {
        payload = Number.parseInt($event.target.textContent);
      }

      // Handle previous and next links
      this.toggleAnchorLinks(payload);

      localStorage.setItem("currentPage", payload.toString());

      const KEY_ENTER = 13;
      if ($event.keyCode === KEY_ENTER) {
        this.$emit("updatePage", this.pageNum);
      } else if (
        $event.type === "click" &&
        $event.target.textContent === "Jump"
      ) {
        this.$emit("updatePage", this.pageNum);
      } else if ($event.type === "click") {
        this.$emit("updatePage", payload);
      }
    },

    /**
     * Initializes active and non active pagination links
     * @param number pageNumber
     * @returns void
     */
    initAnchorState() {
      const links = this.links;
      for (let i = 0; i < links.length; i++) {
        if (i == 0 || i == links.length - 1) {
          continue;
        }
        this.setAnchorState(i, links[i].active);
      }
    },

    /**
     * Marks active pagination link
     * @param number pageNumber
     * @returns void
     */
    setAnchorState(index: number, status: boolean) {
      const anchorTag = document.getElementById(
        "anchor-" + index
      ) as HTMLAnchorElement;

      if (anchorTag != null) {
        if (status) {
          setTimeout(() => {
            if (anchorTag.style) {
              anchorTag.style.cssText = `
              color: #00BFFF;
              cursor: not-allowed;
              opacity: 0.5;
              text-decoration: none;
              pointer-events: none;
              `;
            }
          }, 1);
        } else {
          setTimeout(() => {
            if (anchorTag.style != null || undefined) {
              anchorTag.style.cssText = `
              color: "";
              cursor: "";
              opacity: "";
              text-decoration: "";
              pointer-events: "";
              `;
            } else {
              //
            }
          }, 1);
        }
      }
    },

    /**
     * Togggle pagination links
     * @param number pageNumber
     * @returns void
     */
    toggleAnchorLinks(payload: number) {
      const firstIndex = "0";
      const lastIndex = (this.links.length - 1).toString();
      const nextAnchorTag = document.getElementById(lastIndex);
      const prevAnchorTag = document.getElementById(firstIndex);

      // Last page => disable Next link
      if (payload == this.lastPage) {
        if (nextAnchorTag?.style) {
          nextAnchorTag.style.cssText = `
              color: currentColor;
              cursor: not-allowed;
              opacity: 0.5;
              text-decoration: none;
              pointer-events: none;
              `;
        }
      }
      // on every other link
      else if (payload < this.lastPage) {
        if (payload != 1) {
          if (nextAnchorTag?.style) {
            nextAnchorTag.style.cssText = `
              color: "";
              cursor: "";
              opacity: "";
              text-decoration: "";
              pointer-events: "";
              `;
          }
          if (prevAnchorTag?.style) {
            prevAnchorTag.style.cssText = `
              color: "";
              cursor: "";
              opacity: "";
              text-decoration: "";
              pointer-events: "";
              `;
          }
        } else {
          //  disable Previous link
          if (prevAnchorTag?.style) {
            prevAnchorTag.style.cssText = `
              color: currentColor;
              cursor: not-allowed;
              opacity: 0.5;
              text-decoration: none;
              pointer-events: none;
              `;
          }
        }
      }
    },
    /**
     * Formats number with leading hashtag and zeros
     * @returns string
     */
    pad(num) {
      //fixed size
      const SIZE = 9;
      num = num.toString();

      while (num.length < SIZE) {
        num = "0" + num;
      }

      return "#" + num;
    },

    /**
     * Calculates the time since block's creation
     * @returns string
     */
    calcElapsedTime(time) {
      //if it's a protection block
      if (time == null) {
        return "";
      } else {
        // data string to milisec
        const createdTime = Number(new Date(time)); // UTC time
        const nowUtc = Number(new Date(this.getCurrentTimeUTC())); // UTC time
        const diff = Number(nowUtc - createdTime);

        return this.msToTime(diff);
      }
    },

    /**
     * Gets the UTC time
     * @returns string
     */
    getCurrentTimeUTC() {
      //RETURN:
      //      = number of milliseconds between current UTC time and midnight of January 1, 1970
      const tmLoc = new Date();
      //The offset is in minutes -- convert it to ms
      return tmLoc.getTime() + tmLoc.getTimezoneOffset() * 60000;
    },

    /**
     * Formats miliseconds to hh:mm:ss
     * @returns string
     */
    msToTime(s) {
      // Pad to 2 or 3 digits, default is 2
      function pad(n, z?) {
        z = z || 2;
        return ("00" + n).slice(-z);
      }

      const ms = s % 1000;
      s = (s - ms) / 1000;
      const secs = s % 60;
      s = (s - secs) / 60;
      const mins = s % 60;
      const hrs = (s - mins) / 60;

      return pad(hrs) + ":" + pad(mins) + ":" + pad(secs) + "." + pad(ms, 3);
    }
  }
});
</script>

<style scoped>
a {
  cursor: pointer;
}
a:active {
  top: 2px;
  /* color:dodgerblue */
}

td .cut {
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
th,
tr,
td {
  text-align: center;
}
#info {
  cursor: pointer;
}
input {
  margin-top: 5px;
  height: 25px;
}
</style>
