import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import TableComponent from "@/views/pages/blocks/TableComponent.vue";
import store from "@/store";
export default defineComponent({
    name: "TableData",
    components: {
        TableComponent: TableComponent
    },
    data: function () {
        return {
            blockData: {}
        };
    },
    setup: function () {
        localStorage.setItem("clear", "0");
        var currentPage = localStorage.getItem("currentPage");
        store.dispatch("fetchPageData", currentPage);
        onMounted(function () {
            setCurrentPageTitle("Blocks List and Details");
        });
    },
    created: function () {
        var _this = this;
        // Delay is the key here !
        setTimeout(function () {
            if (localStorage.getItem("clear") === "0") {
                _this.blockData = store.getters.allBlocksData;
            }
        }, 500);
    },
    methods: {
        fetchPage: function ($event) {
            var _this = this;
            store.dispatch("fetchPageData", $event);
            setTimeout(function () {
                _this.blockData = store.getters.allBlocksData;
            }, 500);
        }
    }
});
